export const SOCIAL_MEDIA_LINKS = {
  FACEBOOK: "https://www.facebook.com/MangalVidyaPeeth/",
  INSTAGRAM: "https://instagram.com/mangalvidyapeeth?igshid=YmMyMTA2M2Y=",
  YOUTUBE: "https://youtube.com/c/MangalVidyapeeth",
};

/***
 * !NOTE:
 * contact structure
 *
 * {
 *  id: {string} keep it in sequence
 *  purpose: {string} 2-3 words
 *  details: [array of detail]
 * }
 *
 * detail structure
 * {
 *  name: {string} contact person details
 *  phoneNumber: {string} contact person phone number in proper format Ex. +91-79995-23196
 *  email: {string} proper email format Ex. rishabh.14@atma.club
 * }
 */
export const contacts = [
  {
    id: "1",
    purpose: "पाठ्य सामग्री / Course Material",
    details: [
      {
        name: "Mangalarthi Saudharm Luhadiya",
        phoneNumber: "+91 8171112391",
      },
    ],
  },
  {
    id: "2",
    purpose: "सामान्य प्रश्न / MVP General Queries",

    details: [
      {
        name: "Mangalarthi Samkit Jain",
        phoneNumber: "+91 8279559830",
      },
    ],
  },
  {
    id: "3",
    purpose: "बैच-शिक्षक संबंधित प्रश्न / Batch-Teacher related queries",

    details: [
      {
        name: "Mangalarthi Shaleen Jain",
        phoneNumber: "+91 9479756410",
      },
      {
        name: "Mangalarthi Sulabh Jain",
        phoneNumber: "+91 9024335159",
      },
    ],
  },
  {
    id: "4",
    purpose: "तकनीकी समर्थन / Technical Support",
    details: [
      {
        name: "Mangalarthi Shantanu Jain",
        phoneNumber: "+91 9030329803",
      },
      {
        name: "मंगल विद्यापीठ तकनीकी समर्थन / Mangal Vidya peeth technical support",
        email: "neelanshu.08@atma.club / rishabh.14@atma.club / shohil.12@atma.club",
      },
    ],
  },
];
