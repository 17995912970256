import { db } from "../firebase";

const COURSES_COLLECTION = "courses";

export async function getCourses() {
  const snap = await db
    .collection(COURSES_COLLECTION)
    .orderBy("timestamp", "asc")
    .get();
    return new Promise((resolve, reject) => {
      if (!snap.empty) {
        const courses = snap.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        resolve(courses);
      } else {
        console.warn(`no courses found`);
        resolve([]);
      }
    });
}

export const getCourseById = (uid) => {
    if (!uid) return;
    return new Promise(async (resolve, reject) => {
      try{
        const snapshot = await db
          .collection(COURSES_COLLECTION)
          .doc(uid)
          .get();
          if(snapshot.exists){
            const currentActiveCourses = {
                id: snapshot.id,
                data: snapshot.data(),
              };
            resolve(currentActiveCourses);
        }
        else{
            resolve(null);
        }
      }
      catch (err) {
          console.log(`error encounted while Fetching Course By Uid ${err}`);
          reject(err);
      }
    })
}

export async function extractCourseSnapshot(snapshot) {
  return new Promise((resolve, reject) => {
    const courses = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    console.log("found following courses = ", courses);
    if (courses.length > 0) {
      resolve(courses);
    } else {
      reject("no course found");
    }
  });
}

export async function getCourseByCourseId(courseId) {
  const snap = await db
    .collection(COURSES_COLLECTION).doc(courseId)
    .get();
  return new Promise((resolve, reject) => {
    if(snap.exists){
      resolve({id: snap.id, data: snap.data()});
    }
    else {
      reject(`no Course found for courseId=${courseId}`);
    }
  });
}
