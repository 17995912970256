const BASE_URL = 'https://www.universal-tutorial.com/api';
const API_TOKEN = 'Ju54XEZcbA499cdLLaW55U7-cr91DrjLZRrWb6JO_K8FG9MwM1SgQZN0qiMeKYTNQ50';
const USER_EMAIL = 'rishabh.rj02@gmail.com';
let AUTH_TOKEN = null;

export async function getAuthToken() {
    if(AUTH_TOKEN){
        console.log('auth_token recovered = ', AUTH_TOKEN);
        return AUTH_TOKEN;
    }

    try {
        const response = await fetch(BASE_URL +'/getaccesstoken', {
            method: 'GET',
            headers: new Headers({
                "Accept": "application/json",
                "api-token": API_TOKEN,
                "user-email": USER_EMAIL
            })
        });

        const {auth_token}  = await response.json();
        console.log('auth_token = ', auth_token);
        AUTH_TOKEN = auth_token;
        return AUTH_TOKEN;
    }
    catch (error) {
        console.log('error while fetching auth token - ', error);
    }
}


export async function getCountries() {

    const lsCountries = localStorage.getItem("countries");
    if(lsCountries) {
        return JSON.parse(lsCountries);
    }

    const auth_token = await getAuthToken();
    try {
        const response = await fetch(BASE_URL + '/countries', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer '+ auth_token,
                "Accept": "applicaiton/json"
            })
        });

        const countries = await response.json();
        localStorage.setItem('countries', JSON.stringify(countries));
        return countries;
    }   
    catch(error) {
        console.log("error while fetching countries = ",error);
    }
}


export async function getStates(country){
    if(!country){
        console.log("Invalid request, please pass a country for which you need state 😞");
        return;
    }

    const lsStates = localStorage.getItem(country + '__states');
    if(lsStates) {
        return JSON.parse(lsStates);
    }

    const auth_token = await getAuthToken();
    try {
        const response = await fetch(`${BASE_URL}/states/${country}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer '+ auth_token,
                "Accept": "applicaiton/json"
            })
        });

        const states = await response.json();
        localStorage.setItem(country + '__states', JSON.stringify(states));
        return states;
    }
    catch(error){
        console.log(`Error while fetching states for ${country} = ${error}`);
    }
}


export async function getCities(state) {
    if(!state){
        console.log("Invalid request, please pass a state for which you need cities 😞");
        return;
    }

    const lsCities = localStorage.getItem(state + '__cities');
    if(lsCities) {
        return JSON.parse(lsCities)
    }

    const auth_token = await getAuthToken();
    try {
        const response = await fetch(`${BASE_URL}/cities/${state}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer '+ auth_token,
                "Accept": "applicaiton/json"
            })
        });

        const cities = await response.json();
        localStorage.setItem(state + '__cities', JSON.stringify(cities));
        return cities;
    }
    catch (error) {
        console.log(`Error while fetching cities for ${state} = ${error}`);
    }
}
