import "./MainAppBar.css";

import * as React from "react";

import { Avatar, Button } from "@mui/material";

import AppBar from "@mui/material/AppBar";
import AppDrawer from "./app-drawer/AppDrawer";
import { Apps } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { CSSTransition } from "react-transition-group";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Footer from "../footer/Footer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ProfileProvider from "../../contexts/ProfileContexts";
import PropTypes from "prop-types";
import SocialMedia from "../social-media-handels/SocialMedia";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../../mvp_logo_only.png";
import logoText from "../../mvp_logo_text.png";
import { useAuth } from "../../contexts/AuthContexts";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const drawerWidth = 240;

function MainAppBar(props) {
  const navigate = useNavigate();
  const { window, drawerOptions } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // currentUser
  const { currentUser, logout } = useAuth();

  const handleLogOut = async () => {
    handleClose();
    await logout();
    navigate("/signin");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          zIndex: "99999",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {currentUser && (
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
              className="mainAppBar__menuIcon"
            >
              <MenuIcon />
            </IconButton>
          )}

          <div className="mainAppBar__logo__section">
            <Box
              component="img"
              className="mainAppBar__logo"
              src={logo}
              alt=""
              sx={{ display: { xs: "none", sm: "block" } }}
              onClick={() => navigate("/")}
            />
            <Box
              component="img"
              className="mainAppBar__logo"
              src={logoText}
              alt=""
              sx={{ height: { xs: "48px", sm: "60px" } }}
              onClick={() => navigate("/")}
            />
          </div>

          {currentUser && (
            <>
              <Button
                variant="text"
                sx={{
                  width: { xs: "26px", sm: "82px" },
                  height: { xs: "26px", sm: "30px" },
                  cursor: "pointer",
                }}
                onClick={handleLogOut}
              >
                Logout
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      {!currentUser && (
        <Box className="mainAppBar__no__current_user">{props.children}</Box>
      )}
      {currentUser && (
        <>
          <ProfileProvider>
            <AppDrawer
              drawerWidth={drawerWidth}
              container={container}
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                width: `calc(100% - ${drawerWidth}px)`,
                padding: "0px",
              }}
            >
              <Toolbar />
              <Box
                className="mainAppBar__content__wrapper"
                onClick={() => {
                  if (mobileOpen) {
                    setMobileOpen(false);
                  }
                }}
              >
                <div className="mainAppBar__content__background"></div>
                <Box
                  sx={{
                    marginLeft: { xs: "25px", sm: "55px" },
                    marginRight: { xs: "25px", sm: "55px" },
                    gap: "30px",
                    overflowX: { xs: "auto" },
                  }}
                  className="mainAppBar__content"
                >
                  {props.children}
                </Box>
                <Footer />
              </Box>
            </Box>
          </ProfileProvider>
        </>
      )}
    </Box>
  );
}

MainAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainAppBar;
