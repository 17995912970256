import { utils, writeFile } from "xlsx";

export const exportToExcel = (jsonData, fileName, type) => {


  if(type === 'enrollment'){
    jsonData = jsonData.map(data => {
      const keys = Object.keys(data);
      const visibleKeys = keys.filter(k => data[k].hidden == null);
      const row = {};
      console.log(visibleKeys);
      for(const key of visibleKeys){
        const obj = data[key];
        const k = obj[`label`];
        const v = obj[`value`];
        row[k] = v;
      }
      console.log(row);
      return row;
    })
  }

  console.log(`Exporting to ${fileName}.xlsx`)
  const worksheet = utils.json_to_sheet(jsonData);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "SheetJS");
  writeFile(workbook, `${fileName}.xlsx`);
};
