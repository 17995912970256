import React, { useContext, useEffect, useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth, db } from "../firebase";

import MVP_ACTIONS from "../helpers/constants";
import { useDispatch } from "react-redux";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("auth state changed...");
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  function setupRecapta(mobileNumber) {
    console.log("setting up captcha");
    const recapchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recapchaVerifier.render();
    return signInWithPhoneNumber(auth, mobileNumber, recapchaVerifier);
  }

  function logout() {
    return auth.signOut();
  }

  const value = {
    currentUser,
    loading,
    setupRecapta,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
