import {
  AccountCircle,
  CalendarMonth,
  Dashboard,
  Favorite,
  FormatListNumberedSharp,
  Help,
  LibraryBooks,
  People,
  Person,
  School,
  Settings
} from "@mui/icons-material";

import BatchForm from "../components/batch-form/BatchForm";
import BatchManager from "../components/batch-manager/BatchManager";
import CourseForm from "../components/course-form/CourseForm";
import CourseManager from "../components/course-manager/CourseManager";
import EnrollmentForm from "../components/enrollment-form/EnrollmentForm";
import EnrollmentManager from "../components/enrollment-manager/EnrollmentManager";
import HelpAndSupport from "../components/help-and-support/HelpAndSupport";
import PATH from "../helpers/Path";
import PageNotFound from "../components/page-not-found/PageNotFound";
import Profile from "../components/profile/Profile";
import SessionForm from "../components/session-form/SessionForm";
import SessionManager from "../components/session-manager/SessionManager";
import TeacherForm from "../components/teacher-form/TeacherForm";
import TeacherManager from "../components/teacher-manager/TeacherManager";

const drawerOptions = [
  {
    id: 101,
    icon: <LibraryBooks />,
    label: "Courses",
    path: PATH.COURSES,
    component: <CourseManager />,
    isDrawerOption: true,
    isDefaultRoute: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true,
  },
  {
    id: 102,
    icon: <CalendarMonth />,
    label: "Sessions",
    path: PATH.SESSIONS,
    component: <SessionManager />,
    isDrawerOption: true,
    isDefaultRoute: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 103,
    icon: <Person/>,
    label: "Teachers",
    path: PATH.TEACHERS,
    component: <TeacherManager/>,
    isDrawerOption: true,
    isDefaultRoute: false,
    secondaryOption: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 104,
    icon: <School />,
    label: "Batches",
    path: PATH.BATCHES,
    component: <BatchManager />,
    isDrawerOption: true,
    isDefaultRoute: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  // {
  //   id: 105,
  //   icon: <Settings />,
  //   label: "Settings",
  //   path: "/settings",
  //   component: <Dashboard />,
  //   isDrawerOption: true,
  //   isDefaultRoute: false,
  //   secondaryOption: true,
  //   userAllowed: false,
  //   teacherAllowed: false,
  //   adminAllowed: true
  // },
  {
    id: 106,
    icon: <People />,
    label: "Enrollments",
    path: PATH.ENROLLMENTS,
    component: <EnrollmentManager />,
    isDrawerOption: true,
    isDefaultOption: false,
    isDefaultRoute: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 107,
    icon: <AccountCircle />,
    label: "My Profile",
    path: PATH.PROFILE,
    component: <Profile/>,
    isDrawerOption: true,
    isDefaultOption: true,
    isDefaultRoute: true,
    secondaryOption: true,
    userAllowed: true,
    teacherAllowed: true,
    adminAllowed: true
  },
  // {
  //   id: 108,  
  //   icon: <Favorite />,
  //   label: "Prefrences",
  //   path: "/preferenes",
  //   component: <PageNotFound />,
  //   isDrawerOption: true,
  //   isDefaultRoute: false,
  //   secondaryOption: true,
  //   userAllowed: false,
  //   teacherAllowed: false,
  //   adminAllowed: true
  // },
  {
    id: 109,  
    path: PATH.ADD_COURSE,
    component: <CourseForm />,
    isDrawerOption: false,
    isDefaultRoute: false,
    secondaryOption: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 110,  
    path: PATH.EDIT_COURSE,
    component: <CourseForm />,
    isDrawerOption: false,
    isDefaultRoute: false,
    secondaryOption: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 111,  
    path: PATH.ADD_SESSION,
    component: <SessionForm />,
    isDrawerOption: false,
    isDefaultRoute: false,
    secondaryOption: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 111,  
    path: PATH.EDIT_SESSION,
    component: <SessionForm />,
    isDrawerOption: false,
    isDefaultRoute: false,
    secondaryOption: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 112,  
    path: PATH.ADD_BATCH,
    component: <BatchForm />,
    isDrawerOption: false,
    isDefaultRoute: false,
    secondaryOption: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 113,  
    path: PATH.EDIT_BATCH,
    component: <BatchForm />,
    isDrawerOption: false,
    isDefaultRoute: false,
    secondaryOption: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 114,  
    path: PATH.ADD_TEACHER,
    component: <TeacherForm />,
    isDrawerOption: false,
    isDefaultRoute: false,
    secondaryOption: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 115,  
    path: PATH.EDIT_TEACHER,
    component: <TeacherForm />,
    isDrawerOption: false,
    isDefaultRoute: false,
    secondaryOption: false,
    userAllowed: false,
    teacherAllowed: false,
    adminAllowed: true
  },
  {
    id: 116,  
    path: PATH.PROFILE_STATUS,
    component: <Profile />,
    isDrawerOption: false,
    isDefaultRoute: true,
    secondaryOption: false,
    userAllowed: true,
    teacherAllowed: true,
    adminAllowed: true
  },
  // {
  //   id: 117,
  //   path: PATH.ADD_ENROLLMENT,
  //   component: <EnrollmentForm />,
  //   isDrawerOption: false,
  //   isDefaultOption: false,
  //   isDefaultRoute: false,
  //   userAllowed: false,
  //   teacherAllowed: false,
  //   adminAllowed: true
  // },
  {
    id: 118,
    label: "Enroll Now",
    path: PATH.NEW_ENROLLMENT,
    component: <EnrollmentForm />,
    isDrawerOption: true,
    isDefaultOption: false,
    isDefaultRoute: false,
    userAllowed: true,
    teacherAllowed: false,
    adminAllowed: false
  },
  // {
  //   id: 119,
  //   path: PATH.EDIT_BATCHES,
  //   component: <BatchForm />,
  //   isDrawerOption: false,
  //   isDefaultRoute: false,
  //   userAllowed: false,
  //   teacherAllowed: false,
  //   adminAllowed: true
  // },
  {
    id: 120,
    label: "Help",
    path: PATH.HELP_AND_SUPPORT,
    component: <HelpAndSupport />,
    icon: <Help />,
    isDrawerOption: true,
    isDefaultOption: true,
    isDefaultRoute: true,
    secondaryOption: true,
    userAllowed: true,
    teacherAllowed: true,
    adminAllowed: true
  },
];

export default drawerOptions;
