import Notifications from "../../notifications/Notifications";
import React from "react";

function AdminDashboard() {
  return (
    <>
      <Notifications />
    </>
  );
}

export default AdminDashboard;
