import { Alert, Divider } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getActiveEnrollmentByUid, getEnrollments } from "../../api/enroll";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Container from "../custom/container/Container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { getBatches } from "../../api/batch";
import { getCourses } from "../../api/course";
import { getSessions } from "../../api/session";
import { getTeachers } from "../../api/teacher";
import styled from "styled-components";
import { timeSince } from "../../helpers/timeSince";
import { useSelector } from "react-redux";

function MyEnrollments() {
  const { uid, currentActiveSession } = useSelector((state) => state.mvp);
  const [error, setError] = useState();
  const [userEnrollments, setUserEnrollments] = useState([]);
  const [loading, setLoading] = useState(true);

  const initializeCurrentUserEnrollments = async () => {
    setLoading(true);
    setError(null);
    try {
      // get all the enrollments
      const enrollmentList = await getActiveEnrollmentByUid(uid);
      // const eligibleEnrollment = enrollmentList.filter(
      //   ({ data }) => data.batch && data.batch !== ""
      // );

      // get all the teachers
      const teacherList = await getTeachers();

      // get all the batches
      const batchList = await getBatches();

      // get all the sessions
      const sessionList = await getSessions();

      // get all the courses
      const courseList = await getCourses();

      if(enrollmentList?.length > 0){
        const finalData = enrollmentList.map(({ id, data: enrollment }) => {
          let primaryTeacherId = batchList.find(
            ({ id }) => id === enrollment.batch
          )?.data.primaryTeacher;
  
          let secondaryTeacherId = batchList.find(
            ({ id }) => id === enrollment.batch
          )?.data.secondaryTeacher;
  
          const batchDetails = batchList.find(
            (batch) => batch.id === enrollment.batch
          );
  
          // !7.10.2022 We are not storing session in the enrollment document,
          // !this can be a problem from next session enrollment
          // ! we need to find a solution...
          let sessionDetails = sessionList.find(
            (session) => session.id === enrollment.session
          );
  
          if (!sessionDetails && currentActiveSession) {
            sessionDetails = currentActiveSession;
          }
  
          const courseDetails = courseList.find(
            (course) => course.id === enrollment.course
          );
  
          const primaryTeacherDetails = teacherList.find(
            (teacher) => teacher.id === primaryTeacherId
          );
  
          const secondaryTeacherDetails = teacherList.find(
            (teacher) => teacher.id === secondaryTeacherId
          );
  
          const enrolledOn = enrollment.timestamp;
  
          return {
            courseDetails,
            batchDetails,
            sessionDetails,
            primaryTeacherDetails,
            secondaryTeacherDetails,
            enrolledOn,
          };
        });
  
        console.log("my enrollments = ", enrollmentList);
        console.log("finalData = ", finalData);
        setUserEnrollments(finalData);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError(
        `क्षमा करें, हम आपके नामांकन प्राप्त नहीं कर सके। कृपया डेवलपर की टीम को सूचित करें/Sorry, we could not fetch your enrollments. Please inform the developer's team`
      );
      setLoading(false);
    }
  };

  const initialize = () => {
    initializeCurrentUserEnrollments();
  };

  useEffect(() => {
    initialize();
  }, [currentActiveSession]);

  return (
    <Container
      heading="📑 Your Enrollments"
      helperText={
        userEnrollments.length > 0
          ? `You have enrolled for ${userEnrollments.length} courses`
          : `आपने अभी तक किसी भी पाठ्यक्रम में दाखिला नहीं लिया है/You have not enrolled in any course yet`
      }
      basic={true}
      loading={loading}
    >
      {error && (
        <Alert variant="outlined" severity="error">
          {error}
        </Alert>
      )}

      <EnrollmentDetails enrollments={userEnrollments} />
    </Container>
  );
}

function EnrollmentDetails({ enrollments }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {enrollments.map(
        (
          {
            courseDetails,
            batchDetails,
            sessionDetails,
            primaryTeacherDetails,
            secondaryTeacherDetails,
            enrolledOn,
          },
          index
        ) => (
          <Accordion
            key={Math.random() * 200}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={{ width: { xs: "100%", sm: "60%" }, flexShrink: 0 }}
              >
                📚 {courseDetails.data.courseNameTrns} /{" "}
                {courseDetails.data.courseName}
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  display: { xs: "none", sm: "block" },
                }}
              >
                {sessionDetails?.data.startMonth}{" "}
                {sessionDetails?.data.startYear}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {timeSince(enrolledOn)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CourseDetails course={courseDetails} />
              <StyledDivider />
              <SessionDetails session={sessionDetails} />
              <StyledDivider />
              <BatchDetails batch={batchDetails} />
              <StyledDivider />
              <PrimaryTeacherDetails teacher={primaryTeacherDetails} />
              <StyledDivider />
              <SecondaryTeacherDetails teacher={secondaryTeacherDetails} />
            </AccordionDetails>
          </Accordion>
        )
      )}
    </>
  );
}

function CourseDetails({ course }) {
  return (
    <>
      <h4>पाठ्यक्रम विवरण/Course Details</h4>
      <Typography>{course.data.descriptionTrns}</Typography>
      <br />
      <Typography>{course.data.description}</Typography>
    </>
  );
}

function BatchDetails({ batch }) {
  const heading = <h4>बैच विवरण/Batch Details</h4>;
  if (!batch) {
    return (
      <>
        {heading}
        <Alert variant="outlined" severity="warning">
          आपको बैच में नहीं जोड़ा गया है/You are not added to a batch
        </Alert>
      </>
    );
  }
  return (
    <>
      {heading}
      <Field>
        <Key>Slot Day</Key>
        <Value>{batch.data.slotDay}</Value>
      </Field>
      <Field>
        <Key>Slot Time</Key>
        <Value>{batch.data.slotTime}</Value>
      </Field>
    </>
  );
}

function SessionDetails({ session }) {
   const heading = <h4>सत्र विवरण/Session Details</h4>;
  if (!session) {
    return (
      <>
        {heading}
        <Alert variant="outlined" severity="error">
          हमारे पास इस पाठ्यक्रम के लिए सत्र की जानकारी नहीं है/We don't have
          session information for this course
        </Alert>
      </>
    );
  }
  return (
    <>
      {heading}
      <Field>
        <Key>Session</Key>
        <Value>
          {session.data.startMonth} {session.data.startYear}
        </Value>
      </Field>
    </>
  );
}

function PrimaryTeacherDetails({ teacher }) {
  const heading = <h4>प्राथमिक अध्यापक/Primary Teacher</h4>;
  if (!teacher) {
    return (
      <>
        {heading}
        <Alert variant="outlined" severity="error">
          आपको कोई प्राथमिक शिक्षक नहीं सौंपा गया है/No primary teacher is
          assigned to you
        </Alert>
      </>
    );
  }

  return (
    <>
      {heading}
      <Field>
        <Key>Name</Key>
        <Value>{teacher.data.name}</Value>
      </Field>
    </>
  );
}

function SecondaryTeacherDetails({ teacher }) {
  const heading = <h4>सहायक अध्यापक/Secondary Teacher</h4>;
  if (!teacher) {
    return (
      <>
        {heading}
        <Alert variant="outlined" severity="info">
          आपको कोई सहायक शिक्षक नहीं सौंपा गया है/No helper teacher is assigned
          to you
        </Alert>
      </>
    );
  }
  return (
    <>
      {heading}
      <Field>
        <Key>Name</Key>
        <Value>{teacher.data.name}</Value>
      </Field>
    </>
  );
}

const Field = styled.div`
  display: flex;
  margin: 5px;
`;

const Key = styled.span`
  font-weight: bold;
  flex: 0.5;
`;

const Value = styled.span`
  flex: 0.5;
`;

const StyledDivider = styled(Divider)`
  margin-top: 20px;
  margin-bottom: 25px;
`;
export default MyEnrollments;
