import "./CourseManager.css";

import React, { useEffect, useState } from "react";

import {
  Alert,
} from "@mui/material";
import AlertDialog from "../custom/alert-dialog/AlertDialog";
import Container from "../custom/container/Container";
import CustomCard from "../custom/card/CustomCard";
import PATH from "../../helpers/Path";
import { db } from "../../firebase";
import { timeSince } from "../../helpers/timeSince";
import { useNavigate } from "react-router-dom";

function CourseManager() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [hindi, setHindi] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tileView, setTileView] = useState(true);
  const [listView, setListView] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");

  // delete feature
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deletableCourse, setDeletableCourse] = useState({
    id: null,
    courseName: null,
  });

  useEffect(() => {
    db.collection("courses")
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        setCourses(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        setLoading(false);
      });
  }, []);

  const handleDelete = (id, courseName) => {
    setDeletableCourse({ id, courseName });
    setOpenDeleteConfirmation(true);
  };

  const handleConfirmDialog = () => {
    setOpenDeleteConfirmation(false);
    db.collection("courses")
      .doc(deletableCourse.id)
      .delete()
      .then((success) => {
        console.log("deleted successfully");
        setMsg("Course Deleted Successfully !");
        setOpen(true);
        setSeverity("success");
      })
      .catch((failure) => {
        console.log("failed to delete");
        setMsg("Failed To Delete Course !");
        setOpen(true);
        setSeverity("success");
      });
  };

  return (
    <Container
      heading="Courses"
      basic={true}
      enableHeaderButton={true}
      btnText="Course"
      handleOnClick={() => navigate(`${PATH.ADD_COURSE}`)}
      helperText="Here you can manage all courses of Mangal Vidya Peeth"
      loading={loading}
    >
      {!loading && courses.length === 0 && (
        <Alert variant="outlined" severity="info">
          No course found!
        </Alert>
      )}

      <div className="courseManager__cardContainer">
        {!loading &&
          courses.length > 0 &&
          tileView &&
          courses.map(
            ({
              id,
              data: {
                courseName,
                courseNameTrns,
                description,
                descriptionTrns,
                notes,
                notesTrns,
                createdBy,
                timestamp,
              },
            }) => (
              <CustomCard
                key={id}
                title={courseName}
                description={description}
                lastAction={timeSince(timestamp, hindi)}
                handleEdit={() => navigate(`${PATH.EDIT_COURSE.replace(":id", `${id}`)}`)}
                handleDelete={() => handleDelete(id, courseName)}
              />
            )
          )}
      </div>

      <AlertDialog
        title="Confirmation"
        description={`Are you sure you want to delete this course - ${deletableCourse.courseName}?`}
        openDialog={openDeleteConfirmation}
        btn1="Cancel"
        btn2="Delete"
        handleCloseDialog={() => setOpenDeleteConfirmation(false)}
        handleConfirmDialog={handleConfirmDialog}
      />
    </Container>
  );
}

export default CourseManager;
