import "./Dashboard.css";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { checkIfUserProfileCompleted, getUserProfile } from "../../api/user";
import { getGuid, stringAvatar, stringToColor } from "../../helpers/utils";
import {
  getSessionById,
  isCurrentActiveSessionAcceptingRegistrations,
} from "../../api/session";
import { useDispatch, useSelector } from "react-redux";

import AdminDashboard from "./admin/AdminDashboard";
import AppBackdrop from "../custom/backdrop/Backdrop";
import BatchManager from "../batch-manager/BatchManager";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Container from "../custom/container/Container";
import CourseManager from "../course-manager/CourseManager";
import LogoutIcon from "@mui/icons-material/Logout";
import MVP_ACTIONS from "../../helpers/constants";
import MyEnrollments from "../my-enrollments/MyEnrollments";
import PATH from "../../helpers/Path";
import TeacherManager from "../teacher-manager/TeacherManager";
import UserDashboard from "./user/UserDashboard";
import { db } from "../../firebase";
import { getActiveEnrollmentByUid } from "../../api/enroll";
import { getBatchesById } from "../../api/batch";
import { getCourseById } from "../../api/course";
import { getGreeting } from "../../helpers/timeSince";
import { useAuth } from "../../contexts/AuthContexts";
import { useProfile } from "../../contexts/ProfileContexts";

function GreetUser() {
  const { userProfile } = useSelector((state) => state.mvp);

  if (!userProfile) {
    return null;
  }

  const name = `${userProfile?.firstName} ${userProfile?.lastName}`;
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="center"
      gap="10px"
    >
      <Avatar
        {...stringAvatar(name)}
        sx={{
          width: { xs: 70, sm: 80 },
          height: { xs: 70, sm: 80 },
          fontSize: 30,
          border: "2px solid white",
          bgcolor: stringToColor(name),
        }}
      />
      <Typography
        sx={{
          fontSize: "32px",
          color: "white",
          textAlign: "left",
        }}
      >
        Hi {name}!
      </Typography>
    </Stack>
  );
}

function Dashboard() {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [isAlreadyEnrolled, setAlreadyEnrolledFlag] = useState(false);
  const [activeEnrollments, setActiveEnrollments] = useState([]);
  const [areResgitrationsOpen, setAreRegistrationsOpen] = useState(false);
  const dispatch = useDispatch();

  const loadUserProfile = async (currentUser) => {
    setLoading(true);
    const { userProfile: profile, isProfileCompleted: isCompleted } =
      await checkIfUserProfileCompleted(currentUser.uid);
    if (!isCompleted) {
      navigate(`${PATH.PROFILE_STATUS.replace(":status", "incomplete")}`);
    }
    setIsProfileCompleted(isCompleted);
    setUserProfile(profile);
    setLoading(false);
  };

  const checkIfRegistrationsHappening = async () => {
    try {
      const response = await isCurrentActiveSessionAcceptingRegistrations();
      setAreRegistrationsOpen(response);
    } catch (err) {
      console.error(err);
    }
  };

  const initialize = async () => {
    await loadUserProfile(currentUser);
    await checkIfRegistrationsHappening();
  };

  useEffect(() => {
    initialize();
  }, []);

  const renderDashboard = () => {
    if (userProfile?.isAdmin) {
      return <AdminDashboard />;
    } else if (userProfile?.isUser) {
      return <UserDashboard />;
    }
  };

  return (
    <>
      <AppBackdrop open={loading} />
      <GreetUser />
      {renderDashboard()}
    </>
  );
}

export default Dashboard;
