import { db } from "../firebase";
import firebase from "firebase/compat/app";

const TEACHERS_COLLECTION = "teachers";

export async function getTeacherByTeacherId(teacherId) {
  const snap = await db.collection(TEACHERS_COLLECTION).doc(teacherId).get();
  return new Promise((resolve, reject) => {
    if (snap.exists) {
      resolve({ id: snap.id, data: snap.data() });
    } else {
      reject(`no teacher found for teacherId=${teacherId}`);
    }
  });
}

export async function getTeachers() {
  const snap = await db.collection(TEACHERS_COLLECTION).get();
  return new Promise((resolve) => {
    if (!snap.empty) {
      const teachers = snap.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      resolve(teachers);
    } else {
      console.warn(`no teachers found`);
      resolve([]);
    }
  });
}
