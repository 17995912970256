import ProfileProvider, { useProfile } from "../../contexts/ProfileContexts";
import React, { useContext } from "react";
import {
  getCurrentActiveSession,
  isCurrentActiveSessionAcceptingRegistrations,
} from "../../api/session";

import Container from "../custom/container/Container";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

function Notifications() {
  //  const userProfile = useProfile();
  const { userProfile, currentActiveSession } = useSelector(
    (state) => state.mvp
  );
  
  const registrationsNotification = () => {
    const { isAdmin, isTeacher, isUser } = userProfile;
    let note = null;
    if (isUser && currentActiveSession) {
      const {
        data: { isAcceptingRegistrations, startMonth, startYear },
      } = currentActiveSession;
      if (isAcceptingRegistrations) {
        note = (
          <Note>
            🔊 कोर्स के लिए नामांकन करने के लिए कृपया{" "}
            <Link to="enrollment/new">यहां</Link> क्लिक करें/Please click{" "}
            <Link to="enrollment/new">here</Link> to enroll for a course
          </Note>
        );
      } else {
        note = (
          <Note>
            🔊 {`Registrations for ${startMonth} ${startYear} are now closed!`}
          </Note>
        );
      }
    } else {
      note = <Note>🔊 Sorry, currently there is no active session.</Note>;
    }
    return !isAdmin && note;
  };

  const genericNotification = () => {
    return <Note>🔊 MVP portal was recently updated🎉</Note>;
  };

  return (
    <>
      <Container heading="🔔 Notifications" helperText="" basic={true}>
        <div style={{ marginTop: "10px" }}>
          {registrationsNotification()}
          {genericNotification()}
        </div>
      </Container>
    </>
  );
}

const Note = styled.div`
  margin-top: 7px;
  margin-bottom: 8px;
`;
export default Notifications;
