import MVP_ACTIONS from '../helpers/constants';
import { combineReducers } from 'redux';

export const initialState = {
    uid: null,
    userProfile: null,
    currentActiveSession: null,
};

export function MvpConfigReducer(state = initialState, action) {
    const { type, payload} = action;
    console.log('action = ', type);
    console.log('payload = ', payload);
    switch(type){
        case MVP_ACTIONS.SET_CURRENT_USER_ID:
            return { ...state, uid: payload };
        case MVP_ACTIONS.SET_USER_PROFILE: 
            return { ...state, userProfile: payload };
        case MVP_ACTIONS.SET_ACTIVE_SESSION:
            return { ...state, currentActiveSession: payload};
        default:
            return { ...state };
    }
}

const appReducer = combineReducers({mvp: MvpConfigReducer});

const rootReducer = (state, action) => {
    if(action.type === MVP_ACTIONS.UNSUBSCRIBE) {
        initialState.uid = null;
        initialState.userProfile = null;
        state = undefined;
    }

    return appReducer(state, action);
}

export default rootReducer;
