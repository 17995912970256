import "./Footer.css";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import PATH from "../../helpers/Path";
import React from "react";
import SocialMedia from "../social-media-handels/SocialMedia";

function Footer() {
  return (
    <Box
      className="footer__container"
      sx={{
        display: "flex",
        justifyContent: { xs: "center", sm: "end" },
        alignItems: { xs: "center", sm: "flex-end" },
        flexDirection: "column",
        paddingInline: '20px'
      }}
    >
      <Link
        to={PATH.HELP_AND_SUPPORT}
        style={{ marginTop: "16px", marginBottom: "-11px", color: "#0b80d8" }}
      >
        <span>सहायता / Help?</span>
      </Link>
      <SocialMedia />
    </Box>
  );
}

export default Footer;
