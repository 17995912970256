import { db } from "../firebase";

const BATCHES_COLLECTION = "batches";

export async function getBatches() {
  const snap = await db
    .collection(BATCHES_COLLECTION)
    .orderBy("timestamp", "asc")
    .get();

  return new Promise((resolve, reject) => {
    if (!snap.empty) {
      const batches = snap.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      resolve(batches);
    } else {
      console.warn(`no batches found`);
      resolve([]);
    }
  });
}

export const getBatchesById = (uid) => {
  if (!uid) return;
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = await db.collection(BATCHES_COLLECTION).doc(uid).get();
      if (snapshot.exists) {
        const currentActiveBatch = {
          id: snapshot.id,
          data: snapshot.data(),
        };
        resolve(currentActiveBatch);
      } else {
        resolve(null);
      }
    } catch (err) {
      console.log(`error encounted while checking Batch By Uid ${err}`);
      reject(err);
    }
  });
};

export async function getBatchByBatchId(batchId) {
  const snap = await db.collection(BATCHES_COLLECTION).doc(batchId).get();
  return new Promise((resolve, reject) => {
    if (snap.exists) {
      resolve({ id: snap.id, data: snap.data() });
    } else {
      reject(`no Batch found for batchId=${batchId}`);
    }
  });
}

export async function getBatchesByCourseId(courseId) {
  if (!courseId) return;
  return await db
    .collection(BATCHES_COLLECTION)
    .where("course", "==", courseId)
    .get();
}

export async function extractBatchSnapshot(snapshot) {
  return new Promise((resolve, reject) => {
    const batches = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    console.log("found following batches = ", batches);
    if (batches.length > 0) {
      resolve(batches);
    } else {
      reject("no batch found");
    }
  });
}
