import { Navigate, Outlet } from "react-router-dom";

import React from "react";
import { useAuth } from "../../../contexts/AuthContexts";

function PrivateRoute({ element: Component, ...rest }) {
  const { currentUser } = useAuth();
  return (currentUser ? <Outlet /> : <Navigate to="/signin" />);
}

export default PrivateRoute;
  