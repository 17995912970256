import "./PageNotFound.css";

import Container from "../custom/container/Container";
import React from "react";

function PageNotFound() {
  return (
    <Container emptyContainer={true}>
      <div className="pageNotFound">
        <h1>🙏</h1>
        <h3>Either you don't have permissions or this page not exists</h3>
        <h3>या तो आपके पास अनुमति नहीं है या यह पृष्ठ मौजूद नहीं है</h3>
        <span>
          Click <a href="/">here</a> to go home
        </span>
      </div>
    </Container>
  );
}

export default PageNotFound;
