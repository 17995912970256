import "./EnrollmentForm.css";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  extractBatchSnapshot,
  getBatches,
  getBatchesByCourseId,
} from "../../api/batch";
import { extractCourseSnapshot, getCourses } from "../../api/course";
import { extractSnapshot, getUserProfile } from "../../api/user";
import { isUserAlreadyEnrolled, saveEnrollment } from "../../api/enroll";

import BoxStack from "../custom/box-stack/BoxStack";
import Container from "../custom/container/Container";
import MenuItem from "@mui/material/MenuItem";
import { set } from "date-fns";
import { useAuth } from "../../contexts/AuthContexts";
import { useNavigate } from "react-router-dom";
import { getCurrentActiveSession } from "../../api/session";

function EnrollmentForm() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [userProfile, setUserProfile] = useState();
  // form values
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [course, setCourse] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDescriptionTrns, setCourseDescriptionTrns] = useState("");
  const [batch, setBatch] = useState("");
  const [batchDescription, setBatchDescription] = useState("");
  const [batchSlotDay, setBatchSlotDay] = useState("");
  const [batchSlotTime, setBatchSlotTime] = useState("");
  const [materialDeliveryPref, setMatDeliveryPref] = useState(false);

  // dropdowns
  const [users, setUsers] = useState([currentUser]);
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);

  // loading
  const [loading, setLoading] = useState(true);

  // dialog
  const [dialog, setDialog] = useState({
    open: false,
    severity: null,
    msg: null,
  });

  useEffect(() => {
    loadDropdowns();
    loadUserProfile();
  }, []);

  const loadUserProfile = async () => {
    try {
      const userProfileSnapshot = await getUserProfile(currentUser.uid);
      const { userProfile: profile, userDocId } = await extractSnapshot(
        userProfileSnapshot
      );
      console.log("found userProfile = ", profile);
      setUserProfile(profile);
      setFullName(`${profile.firstName} ${profile.lastName}`);
      setMobileNumber(profile.mobileNumber);
      setLoading(false);
    } catch (err) {
      console.log("found error while loadUserProfile = ", err);
    }
  };

  const clearForm = () => {
      setMobileNumber(null);
      setFullName(null);
      setCourse(null);
      setBatch(null);
  }

  const enrollStudent = async () => {
    clearForm();
    try {
      const alreadyEnrolled = await isUserAlreadyEnrolled(
        currentUser.uid,
        course,
        batch
      );

      if (alreadyEnrolled) {
        setDialog({
          open: true,
          severity: "error",
          msg: "ऐसा लगता है कि आपने पहले ही पाठ्यक्रम के लिए पंजीकरण कर लिया है (Seems that you have already registered for the course.)",
        });
        return;
      }
      const response = await saveEnrollment({
        uid: currentUser.uid,
        mobileNumber,
        course,
        batch,
        materialDeliveryPref,
      });
      setDialog({
        open: true,
        severity: "success",
        msg: "शुक्रिया! आपने सफलतापूर्वक नामांकन कर लिया है। (Thank you! you have successfully enrolled.)",
      });
    } catch (err) {
      console.log(`failed to enroll ${JSON.stringify(err)}`);
    }
  };

  const loadDropdowns = () => {
    loadUsers();
    loadCourses();
    //loadBatches();
  };

  const loadUsers = () => {
    const cuser = currentUser;
  };

  const loadCourses = async () => {
    try {
      const courseList = await getCourses();
      setCourses(courseList);
    } catch (err) {
      console.log("found error while loadCourses = ", err);
    }
  };

  const loadBatches = async () => {
    try {
      const batchList = await getBatches();
      setBatches(batchList);
    } catch (err) {
      console.log("found error while loadBatches = ", err);
    }
  };

  const loadBatchesForThisCourse = async (courseId) => {
    try {
      setCourse(courseId);
      await setCourseDetails(courseId);
      const batchesByCourseSnap = await getBatchesByCourseId(courseId);
      let batchesList = [];
      batchesList = await extractBatchSnapshot(batchesByCourseSnap);
      const getCurrentActiveSess = await getCurrentActiveSession();
      batchesList = batchesList.filter(b => b.data.session === getCurrentActiveSess.id);
      console.log(
        `Found batch list for courseId=${courseId} => ${JSON.stringify(
          batchesList
        )}`
      );
      setBatches(batchesList);
    } catch (err) {
      console.log("found error while loading batches for this course = ", err);
      resetBatchDependencies();
    }
  };

  const setCourseDetails = (courseId) => {
    return new Promise((resolve) => {
      const c = courses.find((co) => co.id === courseId);
      setCourseDescription(c.data.description);
      setCourseDescriptionTrns(c.data.descriptionTrns);
      resetBatchDependencies();
      resolve(true);
    });
  };

  const resetBatchDependencies = () => {
    setBatchDescription("");
    setBatches([]);
    setBatch("");
    setBatchSlotDay("");
    setBatchSlotTime("");
  };

  const handleBatchChange = (batchId) => {
    const batchSelected = batches.find((b) => b.id === batchId);
    setBatch(batchId);
    setBatchDescription(batchSelected.data.description);
    setBatchSlotDay(batchSelected.data.slotDay);
    setBatchSlotTime(batchSelected.data.slotTime);
  };

  return (
    <>
      <Container
        heading="Enroll for Course"
        loading={loading}
        helperText="Here you can enroll for Mangal Vidya Peeth Courses"
        enableDivider={true}
        enableHeaderButton={false}
        handleSubmit={enrollStudent}
        disableWhen={
          !currentUser.uid ||
          !mobileNumber ||
          !fullName ||
          !course ||
          (batches.length > 0 && !batch)
        }
        align="center"
      >
        <BoxStack
          stackDirection="column"
          boxWidth="25ch"
          stackStyle={{ gap: "1.5px" }}
        >
          <TextField
            fullWidth
            type="text"
            id="name"
            margin="normal"
            label="Full Name"
            variant="outlined"
            value={fullName}
            disabled
            onChange={(e) => console.log(e.target.value)}
          />

          <TextField
            fullWidth
            type="text"
            id="name"
            margin="normal"
            label="Whatsapp Number"
            variant="outlined"
            value={mobileNumber}
            disabled
            onChange={(e) => console.log(e.target.value)}
          />

          {courses.length > 0 && (
            <TextField
              id="course"
              select
              label="Select Course"
              value={course}
              onChange={(e) => loadBatchesForThisCourse(e.target.value)}
              style={{ textAlign: "left" }}
            >
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.data.courseNameTrns
                    ? `${course.data.courseNameTrns} (${course.data.courseName})`
                    : `${course.data.courseNameTrns}`}
                </MenuItem>
              ))}
            
	    </TextField>
          )}

          {courseDescriptionTrns && courseDescription && (
            <div className="enrollmentForm__course__description">
              <Alert severity="success">
                <AlertTitle>विवरण/Description</AlertTitle>
                <div className="enrollmentForm__course__description__p">
                  <pre className="enrollmentForm__pre">{courseDescriptionTrns}</pre>
                  <pre className="enrollmentForm__pre">{courseDescription}</pre>
                </div>
              </Alert>
            </div>
          )}

          {batches.length > 0 && (
            <TextField
              id="batch"
              select
              label="Select Batch"
              value={batch}
              style={{ textAlign: "left" }}
              onChange={(e) => handleBatchChange(e.target.value)}
            >
              {batches
                .sort((a, b) => {
                  let fa = a.data.batchName.toLowerCase(),
                    fb = b.data.batchName.toLowerCase();

                  if (fa < fb) {
                    return -1;
                  }
                  if (fa > fb) {
                    return 1;
                  }
                  return 0;
                })
                .map(({ id, data }) => (
                  <MenuItem key={id} value={id}>
                    {data.batchName}
                  </MenuItem>
                ))}
            </TextField>
          )}

          {batchDescription && (
            <div className="enrollmentForm__course__description">
              <Alert severity="success">
                <AlertTitle>विवरण/Description</AlertTitle>
                <div className="enrollmentForm__course__description__p">
                  <p>{batchDescription}</p>
                  <p>
                    <strong>दिन/Day: </strong>
                    {batchSlotDay}
                  </p>
                  <p>
                    <strong>समय/Time: </strong>
                    {batchSlotTime}
                  </p>
                </div>
              </Alert>
            </div>
          )}

          {course && (
            <div
              className="enrollmentForm__course__description"
              style={{ display: "flex", cursor: "pointer" }}
            >
              <Checkbox
                checked={materialDeliveryPref}
                color="primary"
                onChange={() => setMatDeliveryPref(!materialDeliveryPref)}
              />
              <div
                className="enrollmentForm__course__description"
                onClick={() => setMatDeliveryPref(!materialDeliveryPref)}
              >
                <p>
                  क्या आप इस कोर्स की पाठ्य सामग्री अपने पते पर प्राप्त करना
                  चाहते हैं ?
                </p>
                <p>
                  Do you want to receive course material delivered at your home
                  address ?
                </p>
              </div>
            </div>
          )}
        </BoxStack>

        <Snackbar
          open={dialog.open}
          autoHideDuration={1100}
          onClose={() => navigate("/")}
        >
          <Alert
            onClose={() => navigate("/")}
            severity={dialog.severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {dialog.msg}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}

export default EnrollmentForm;
