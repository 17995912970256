export function timeSince(date, hindi) {
    if(!date){
      return 'Not available'
    }
    console.log("date = ", date.toDate());
    var seconds = Math.floor((new Date() - date.toDate()) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) +  (!hindi  ? " years ago" : " साल पहले");
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) +  (!hindi ? " months ago" : " महीने पहले");
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + (!hindi ? " days ago" : " दिन पहले");
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + (!hindi ? " hours ago" : " घंटे पहले");
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + (!hindi ? " minutes ago" : " मिनट पहले");
    }
    return Math.floor(seconds) + ( !hindi ? " seconds ago" : " सेकंड पहले");
  }


  export const getGreeting = () => {
    const current_date_time = new Date();
    const hour = current_date_time.getHours();

    if (hour >= 0 && hour <= 12) {
      return `Good Morning! 🌞`;
    } else if (hour > 12 && hour <= 17) {
      return "Good Afternoon! 🌅";
    } else {
      return "Good Evening! 🌙";
    }
  };