import MyEnrollments from '../../my-enrollments/MyEnrollments'
import Notifications from '../../notifications/Notifications'
import React from 'react'

function UserDashboard() {
  return (
    <>
        <Notifications />
        <MyEnrollments />
    </>
  )
}

export default UserDashboard