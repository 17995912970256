import { Alert, Box, Button, Divider } from "@mui/material";

import { Add } from "@mui/icons-material";
import React from "react";

function HeaderContainer({
  heading,
  enableHeaderButton,
  ButtonIcon = <Add />,
  handleOnClick,
  btnText="Test",
  enableDivider=true,
  customHeaderButton=null,
}) {
  return (
    <>
      <Box className="container__header__section">
      <h2 className="container__heading">{heading}</h2>
        {(enableHeaderButton === true) ? (
          <Button
            startIcon={ButtonIcon}
            variant="contained"
            onClick={handleOnClick}
            size="small"
            disableElevation
          >
            {btnText}
          </Button>
        ) : null}
        {customHeaderButton}
      </Box>
      {enableDivider && <Divider />}
    </>
  );
}

export default HeaderContainer;
