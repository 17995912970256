import "./SocialMedia.css";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import React from "react";
import { SOCIAL_MEDIA_LINKS } from "../../data/constant";
import YouTubeIcon from "@mui/icons-material/YouTube";

function SocialMedia() {
  return (
    <div className="social__media__container">
      <div className="social__media__header"><strong>Follow us on</strong></div>
      <div className="social__media__footer">
        <a href={SOCIAL_MEDIA_LINKS.FACEBOOK} target="__blank">
          <FacebookIcon className="social__media__icon" fontSize="large"/>
        </a>
        <a href={SOCIAL_MEDIA_LINKS.YOUTUBE} target="__blank">
          <YouTubeIcon className="social__media__icon" fontSize="large"/>
        </a>
        <a href={SOCIAL_MEDIA_LINKS.INSTAGRAM} target="__blank">
          <InstagramIcon className="social__media__icon" fontSize="large"/>
        </a>
      </div>
    </div>
  );
}

export default SocialMedia;
