import { db } from "../firebase";
import firebase from "firebase/compat/app";

const ENROLLMENTS_COLLECTION = "enrollments";

export const getEnrollments =  async () => {
    const snap = await db.collection(ENROLLMENTS_COLLECTION).orderBy("timestamp", "desc").get();
    return new Promise((resolve) => {
        const enrollments =  snap.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        resolve(enrollments);
    })
}

export const saveEnrollment = (enrollment) => {
  const e = {
    ...enrollment,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  };
  return db.collection(ENROLLMENTS_COLLECTION).add(e);
};


export const isUserAlreadyEnrolled = (uid, courseId, batchId) => {
    return new Promise(async (resolve, reject) => {
        try{
            const snapshot = await db.collection(ENROLLMENTS_COLLECTION)
            .where("uid", "==", uid)
            .where("course", "==", courseId)
            .where("batch", "==", batchId)
            .get();
            
            if(snapshot.docs.length > 0){
                resolve(true);
            }
            else{
                resolve(false);
            }
        }
        catch (err) {
            console.log(`error encounted while checking isUserAleadyEnrolled ${err}`);
            reject(err);
        }
    });
}

export const getActiveEnrollmentByUid = (uid) => {
    return new Promise(async (resolve, reject) => {
        try{
            const snapshot = await db.collection(ENROLLMENTS_COLLECTION)
            .where("uid", "==", uid)
            .get();
            if(snapshot.docs.length > 0){
                const currentActiveEnrollments = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                  }));
                resolve(currentActiveEnrollments);
            }
            else{
                resolve(null);
            }
        }
        catch (err) {
            console.log(`error encounted while checking Active Enrollment By Uid ${err}`);
            reject(err);
        }
    });
}