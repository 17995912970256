import { ArrowBack } from "@mui/icons-material";
import { Box } from "@mui/material";
import HelpAndSupport from "./HelpAndSupport";
import { Link } from "react-router-dom";
import React from "react";
import SocialMedia from "../social-media-handels/SocialMedia";

function PublicHelpAndSupport() {
  return (
    <Box width={{ xs: "90%", sm: "100%" }}>
      <Box display="flex" justifyContent="left" marginBottom="10px">
        <Link to="/signin" c="public__help__link">
          <ArrowBack fontSize="large" color="primary" />
        </Link>
      </Box>
      <HelpAndSupport />
      <SocialMedia />
    </Box>
  );
}

export default PublicHelpAndSupport;
