import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

function AppBackdrop({ open = false, onClick, ...rest }) {
  return (
    <>
      <Backdrop
        {...rest}
        sx={{color: "primary", zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={open}
        onClick={onClick}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
}

export default AppBackdrop;
