import "./CustomCard.css";

import { Card, Chip, Divider, IconButton } from "@mui/material";
import {
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  Star
} from "@mui/icons-material";
import React, { useState } from "react";

function CustomCard({
  imgUrl = "https://images.unsplash.com/photo-1614849286521-4c58b2f0ff15?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGNvbG9yZnVsJTIwYmFja2dyb3VuZHxlbnwwfHwwfHw%3D&w=1000&q=80",
  title,
  description,
  lastAction,
  rating = 5,
  handleEdit,
  handleDelete,
}) {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(true);
    console.log("toggling show more")
  }

  const handleShowLess = () => {
    setShowMore(false);
    console.log("toggling show more")
  }

  const generateDescription = (desc) => {
    if (desc?.length > 35) {
      return (
        <div className="customCard_expandDescription">
          <p>{!showMore && (desc.substring(0, 31) + '...')}</p>
          {!showMore && <ExpandMore
            onClick={() => handleShowMore()}
            style={{cursor: 'pointer'}}
          />}
          <p>{showMore && (desc)}</p>
          {showMore && <ExpandLess
            onClick={() => handleShowLess()}
            style={{cursor: 'pointer'}}
          />}
        </div>
      );
    }

    return (<p>{desc}</p>);
  };

  return (
    <Card className="customCard">
      <img src={imgUrl} alt={title} />
      <div className="customCard__header">
        <h2 color="primary">{title}</h2>
        {rating && (
          <Chip
            className="customCard__rating"
            label={rating}
            color={rating >= 3.5 ? "success" : "warning"}
            size="small"
            icon={<Star />}
          />
        )}
      </div>

      <div className="customCard__content">{generateDescription(description)}</div>

      <Divider />

      <div className="customCard__footer">
        <span>{lastAction}</span>
        <div className="customCard__btnGrp">
          <IconButton onClick={handleEdit}>
            <Edit />
          </IconButton>

          <IconButton onClick={handleDelete}>
            <Delete />
          </IconButton>
        </div>
      </div>
    </Card>
  );
}

export default CustomCard;
